import {
  Box,
  Typography} from '@material-ui/core';
import React from 'react';
import '../css/BasicInfo.css';

export default function BasicInfo() {
  return (


    <Box
      className="radiusNone
      paddingTopDistance"
    >
      <Typography variant='body2' className="
      fontOcupation
      colorGreen
      textCenter">
        Desenvolvedor de Software &amp; Técnico de TI
      </Typography>

    </Box>


  );
}
